body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/**
 MODAL
  **/
.backdrop-modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, 0.6);
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bg-drop {
  background-color: rgba(0,0,0, 0.6);
}


/**
Loader
 */
.bg-loader {
  background-color: rgba(255,255,255, 0.8);
}


.loader {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loader-spinner {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
